import { MONEY_SAVED } from "../actions/actionTypes/types";

export interface OtherStateInterface {
  moneySaved: any | null;
}

const INITIAL_STATE = {
  moneySaved: null,
};

export const other = (
  state: OtherStateInterface = INITIAL_STATE,
  action: any
) => {
  switch (action.type) {
    case MONEY_SAVED: {
      const newState = { ...state, moneySaved: action.payload };
      return newState;
    }
    default:
      return state;
  }
};

export default other;
