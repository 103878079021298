import React, { createContext, useContext, useState, ReactNode } from 'react';

interface GlobalStateContextProps {
  index: number;
  setIndex: (index: number) => void;
}

const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(undefined);

export const GlobalStateProvider = ({ children }: { children: ReactNode }) => {
  const [index, setIndex] = useState<number>(1); // This is your global state

  return (
    <GlobalStateContext.Provider value={{ index, setIndex }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};
