import React, { useEffect, useState, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { AutoLogin } from "./store/actions/authActions/AutoLogin";
import {
  SET_APP_INFO,
  SET_SAFE_AREA_INSETS,
  SET_STATUS_BAR_HEIGHT,
} from "./store/actions/actionTypes/types";
import {
  GetApplication,
  GetApplicationVersion,
  IsRunningOverwolf,
} from "./utilityFunctions";
import useFilteredUserMeals from "./components/drawerNav/useFilteredUserMeals";
import useApplicationInfo from "./hooks/useAppInfo";
import { usePatchPromoCodes } from "./hooks/usePatchPromoCodes";
import { RefreshPromoDollars } from "./store/actions/mealsActions/RefreshPromoDollars";
import { Capacitor } from "@capacitor/core";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { SafeArea } from "capacitor-plugin-safe-area";
import "./index.css";
import { GameSessionInfoI } from "./overwolf/features/gameEventTracker/GameEventTracker";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { initializeTray } from "components/systemTray/trayManager";
import { GlobalStateProvider } from "GlobalStateContext";

// Lazy load components
const LandingPage = lazy(() => import("./pages/LandingPage"));
const Footer = lazy(() => import("./components/Footer"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const LoginSmsPage = lazy(() => import("./pages/LoginSmsPage"));
const VerifyEmailPage = lazy(() => import("./pages/VerifyEmailPage"));
const SuccessfulEmailVerificationPage = lazy(
  () => import("./pages/SuccessfulEmailVerificationPage")
);
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const PasswordRecoveryPage = lazy(() => import("./pages/PasswordRecoveryPage"));
const ChangePasswordPage = lazy(() => import("./pages/ChangePasswordPage"));
const AuthRedirection = lazy(() => import("./components/auth/AuthRedirection"));
const AccountInfoPage = lazy(() => import("./pages/AccountInfoPage"));
const AdminDashboardPage = lazy(() => import("./pages/AdminDashboardPage"));
const PrivacyPolicy = lazy(
  () => import("./components/legalDocs/PrivacyPolicy")
);
const TermsOfService = lazy(
  () => import("./components/legalDocs/TermsAndConditions")
);
const DemoPage = lazy(() => import("./pages/DemoPage"));
const ContactPage = lazy(() => import("./pages/ContactPage"));
const ErrorPage = lazy(() => import("./pages/ErrorPage"));
const SignUpPage = lazy(() => import("./pages/SignUpPage"));
const BottomNav = lazy(() => import("./components/bottomNav/BottomNav"));
const CommentModal = lazy(() => import("./components/dashboard/CommentModal"));
const PressDonePromptPage = lazy(() => import("./pages/PressDonePromptPage"));
const SocialMediaProgramPage = lazy(
  () => import("./pages/SocialMediaProgramPage")
);
const OverwolfRedirection = lazy(
  () => import("./components/auth/OverwolfRedirection")
);
const KeywordsHelmet = lazy(() => import("./components/SEO/KeywordsHelmet"));
const FrequentlyAskedPage = lazy(
  () => import("./components/infoSection/FAQ/pages/FrequentlyAskedPage")
);
const AnyOtherQuestionsPage = lazy(
  () => import("./components/infoSection/FAQ/pages/AnyOtherQuestionsPage")
);
const HowDoesLiiferWorkPage = lazy(
  () => import("./components/infoSection/FAQ/pages/HowDoesLiiferWorkPage")
);
const OrderRelatedQuestionPage = lazy(
  () => import("./components/infoSection/FAQ/pages/OrderRelatedQuestionPage")
);
const WhatAreBenefitsOfLiiferPage = lazy(
  () => import("./components/infoSection/FAQ/pages/WhatAreBenefitsOfLiiferPage")
);
const WhatAreLiiferBucksPage = lazy(
  () => import("./components/infoSection/FAQ/pages/WhatAreLiiferBucksPage")
);
const WhatIsLiiferPage = lazy(
  () => import("./components/infoSection/FAQ/pages/WhatIsLiiferPage")
);
const RestaurantList = lazy(
  () => import("./components/dashboard/RestaurantList")
);
const Header = lazy(() => import("./components/header/Header"));
const ConfirmationPage = lazy(() => import("./pages/ConfirmationPage"));
const MobileNotifications = lazy(
  () => import("./components/bottomNav/MobileNotifications")
);
const StreamerPage = lazy(() => import("./pages/StreamerPage"));
const UpdatePendingModal = lazy(
  () => import("./overwolf/features/UpdatePendingModal")
);
const GameEventTracker = lazy(
  () => import("./overwolf/features/gameEventTracker/GameEventTracker")
);
const GameEndedModal = lazy(
  () => import("./overwolf/features/gameEventTracker/GameEndedModal")
);
const WebAppPropertyFetcher = lazy(
  () => import("./components/auth/WebAppPropertyFetcher")
);
const DoubleLiiferBucksModal = lazy(
  () => import("./overwolf/features/DoubleLiiferBucksModal")
);
const FullMaintenanceScreen = lazy(
  () => import("./components/FullMaintenanceScreen")
);
const CapacitorOneSignalNotifications = lazy(
  () => import("./oneSignal/CapacitorOneSignalNotifications")
);
const InGameNotificationModal = lazy(
  () => import("./overwolf/features/notificationWindow/InGameNotificationModal")
);

interface AppProps {
  IsOverwolfDisplay: boolean;
  InGameNotifications?: () => JSX.Element | null;
  OverwolfPropertyFetcher?: any;
}

const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTabletDevice = /tablet|ipad|playbook|silk|android(?!.*mobi)/i.test(
    userAgent
  );
  const isLargeScreen = window.innerWidth >= 768; // Adjust this value based on your needs
  return isTabletDevice || isLargeScreen;
};

const App: React.FC<AppProps> = ({
  IsOverwolfDisplay,
  InGameNotifications,
  OverwolfPropertyFetcher,
}) => {
  const [showGameEndedModal, setShowGameEndedModal] = useState<boolean>(false);
  const [gameSessionInfoList, _setGameSessionInfoList] = useState<
    GameSessionInfoI[]
  >([]);
  const setGameSessionInfoList = (gameInfoSessionList: GameSessionInfoI[]) => {
    if (gameInfoSessionList.length > 4) {
      gameSessionInfoList.pop();
    }
    _setGameSessionInfoList(gameInfoSessionList);
  };

  useEffect(() => {
    if (typeof window !== "undefined" && window.overwolf) {
      initializeTray();
    }
  }, []);

  useEffect(() => {
    const setOrientation = async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          if (isTablet()) {
            // Tablets
            await ScreenOrientation.lock({ orientation: "landscape" });
          } else {
            // Phones
            await ScreenOrientation.lock({ orientation: "portrait" });
          }
        } catch (error) {
          console.error("Error locking screen orientation:", error);
        }
      } else {
        console.log("");
      }
    };

    setOrientation();

    return () => {
      if (Capacitor.isNativePlatform()) {
        ScreenOrientation.unlock();
      }
    };
  }, []);

  const [windowType, setWindowType] = useState<null | string>(null);
  const { application } = useApplicationInfo();
  const userInfo = useAppSelector((state: any) => state.auth.userInfo);
  const disabledGameTimeTracking = userInfo?.disableGameTimeTracking;
  const dispatch = useAppDispatch();
  const isRunningOverwolf = IsRunningOverwolf();
  const isRunningIos = application === "IOS_CLIENT";
  const isRunningCapacitor =
    application === "ANDROID_CLIENT" || application === "IOS_CLIENT";
  const filteredUserMeals = useFilteredUserMeals();
  const [refreshMeal, setRefreshMeal] = useState<boolean>(false);

  useEffect(() => {
    let eventListener: any;
    if (isRunningIos) {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        dispatch({ type: SET_SAFE_AREA_INSETS, payload: insets });
      });

      SafeArea.getStatusBarHeight().then(({ statusBarHeight }) => {
        dispatch({ type: SET_STATUS_BAR_HEIGHT, payload: statusBarHeight });
      });

      const addEventListener = async () => {
        eventListener = await SafeArea.addListener(
          "safeAreaChanged",
          (data) => {
            const { insets } = data;
            dispatch({ type: SET_SAFE_AREA_INSETS, payload: insets });
          }
        );
      };

      addEventListener();
    }

    return () => {
      if (eventListener) {
        eventListener.remove();
      }
    };
  }, [isRunningIos, dispatch]);

  useEffect(() => {
    // sets app info in redux
    if (!windowType) {
      const getWindowType = async () => {
        GetApplication().then((response) => {
          setWindowType(response);
          dispatch({
            type: SET_APP_INFO,
            payload: {
              application: response,
              applicationVersion: GetApplicationVersion(),
            },
          });
        });
      };

      getWindowType();
    }
  }, [windowType, dispatch]);

  // Effect to handle auto login
  useEffect(() => {
    const handleAutoLogin = (userData: any) => {
      dispatch(AutoLogin(userData));
    };

    const authData =
      window.localStorage.getItem("user") ||
      window.sessionStorage.getItem("user");
    if (authData) {
      const userData = JSON.parse(authData);
      const refreshToken = userData?.refreshToken;
      if (!refreshToken) {
        localStorage.removeItem("user");
      } else {
        handleAutoLogin(userData);
      }
    }
  }, [dispatch]);

  const promotionCode = localStorage.getItem("promoCode");
  const handlePromoCodes = usePatchPromoCodes(Navigate).handlePromoCodes;
  const { accessToken, refreshToken } = useAppSelector((state) => state.auth);

  useEffect(() => {
    if (promotionCode && accessToken) {
      handlePromoCodes().then(() => {
        dispatch(
          RefreshPromoDollars(accessToken, refreshToken, userInfo, Navigate)
        );
      });
    }
  }, [
    handlePromoCodes,
    promotionCode,
    accessToken,
    refreshToken,
    userInfo,
    dispatch,
  ]);

  const roles = userInfo?.roles;

  const REACT_APP_DEBUG = useAppSelector(
    (state) => state?.auth?.env?.REACT_APP_DEBUG
  );
  const REACT_APP_DOUBLE_BUCKS_DATES = useAppSelector(
    (state) => state?.auth?.env?.REACT_APP_DOUBLE_BUCKS_DATES
  );
  const REACT_APP_FULL_MAINTENANCE_MODE = useAppSelector(
    (state) => state?.auth?.env?.REACT_APP_FULL_MAINTENANCE_MODE
  );
  const inFullMaintenanceMode = REACT_APP_FULL_MAINTENANCE_MODE === "true";

  if (process.env.NODE_ENV === "production" && REACT_APP_DEBUG !== "true") {
    console.log = () => {};
  }

  const renderDoubleBucksModal =
    !!userInfo &&
    !!REACT_APP_DOUBLE_BUCKS_DATES &&
    REACT_APP_DOUBLE_BUCKS_DATES !== "" &&
    isRunningOverwolf;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ErrorBoundary>
        <GlobalStateProvider>
          {isRunningOverwolf && <UpdatePendingModal />}
          {renderDoubleBucksModal && (
            <DoubleLiiferBucksModal
              datesString={REACT_APP_DOUBLE_BUCKS_DATES}
            />
          )}
          <HelmetProvider>
            <KeywordsHelmet />
            {!OverwolfPropertyFetcher && <WebAppPropertyFetcher />}
            {!!OverwolfPropertyFetcher && <OverwolfPropertyFetcher />}
            {inFullMaintenanceMode && <FullMaintenanceScreen />}
            <Router>
              {!inFullMaintenanceMode && !isRunningCapacitor ? (
                <CommentModal />
              ) : isRunningCapacitor && userInfo ? (
                <CommentModal />
              ) : null}
              {!!userInfo && !!InGameNotifications && <InGameNotifications />}
              {!!userInfo && isRunningCapacitor && (
                <CapacitorOneSignalNotifications />
              )}
              {isRunningOverwolf && <InGameNotificationModal />}
              {isRunningOverwolf &&
                !disabledGameTimeTracking &&
                windowType === "OW_DESKTOP_CLIENT" &&
                !!userInfo && (
                  <>
                    <GameEventTracker
                      setShowGameEndedModal={setShowGameEndedModal}
                      gameSessionInfoList={gameSessionInfoList}
                      setGameSessionInfoList={setGameSessionInfoList}
                    />
                    {gameSessionInfoList.length > 0 && (
                      <GameEndedModal
                        setShowGameEndedModal={setShowGameEndedModal}
                        gameSessionInfoList={gameSessionInfoList}
                        setGameSessionInfoList={setGameSessionInfoList}
                        showGameEndedModal={showGameEndedModal}
                      />
                    )}
                  </>
                )}
              <div className={`${isRunningOverwolf ? "pt-[30px]" : ""}`}>
                {!inFullMaintenanceMode && (
                  <Routes>
                    <Route
                      path="/dashboard/*"
                      element={
                        <DashboardPage
                          refreshMeal={refreshMeal}
                          setRefreshMeal={setRefreshMeal}
                        />
                      }
                    />
                    <Route path="/home" element={<LandingPage />} />
                    {!IsRunningOverwolf() && (
                      <>
                        <Route
                          path="/home/en"
                          element={<LandingPage staticLanguage="en" />}
                        />
                        <Route
                          path="/home/fr"
                          element={<LandingPage staticLanguage="fr" />}
                        />
                      </>
                    )}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/login/sms" element={<LoginSmsPage />} />
                    <Route
                      path="/login/:qrTokenToValidate"
                      element={<LoginPage />}
                    />
                    <Route
                      path="/socialLogin"
                      element={<LoginPage onlySocial={true} />}
                    />
                    <Route
                      path="/socialLogin/:qrTokenToValidate"
                      element={<LoginPage onlySocial={true} />}
                    />
                    <Route
                      path="/verifyemail/:verificationToken"
                      element={<VerifyEmailPage />}
                    />
                    <Route
                      path="/api/users/activate/*"
                      element={<SuccessfulEmailVerificationPage />}
                    />
                    <Route
                      path="/api/users/resetPassword/*"
                      element={<ChangePasswordPage />}
                    />
                    <Route
                      path="/passwordRecovery"
                      element={<PasswordRecoveryPage />}
                    />
                    <Route
                      path="/socialMediaProgram"
                      element={<SocialMediaProgramPage />}
                    />
                    <Route path="/FAQ" element={<FrequentlyAskedPage />} />
                    <Route
                      path="/FAQ/whatIsLiifer"
                      element={<WhatIsLiiferPage />}
                    />
                    <Route
                      path="/FAQ/anyOtherQuestions"
                      element={<AnyOtherQuestionsPage />}
                    />
                    <Route
                      path="/FAQ/howDoesLiiferWork"
                      element={<HowDoesLiiferWorkPage />}
                    />
                    <Route
                      path="/FAQ/orderRelatedQuestion"
                      element={<OrderRelatedQuestionPage />}
                    />
                    <Route
                      path="/FAQ/whatAreBenefitsOfLiifer"
                      element={<WhatAreBenefitsOfLiiferPage />}
                    />
                    <Route
                      path="/FAQ/whatAreLiiferBucks"
                      element={<WhatAreLiiferBucksPage />}
                    />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/signup" element={<SignUpPage />} />
                    <Route
                      path="/signup/:qrTokenToValidate"
                      element={<SignUpPage />}
                    />
                    <Route
                      path="/socialSignup"
                      element={<SignUpPage onlySocial={true} />}
                    />
                    <Route
                      path="/socialSignup/:qrTokenToValidate"
                      element={<SignUpPage onlySocial={true} />}
                    />
                    <Route
                      path="/signupWithCode/:promoCode"
                      element={<LandingPage />}
                    />
                    <Route
                      path="/signupWithReferral/:referrerCode"
                      element={<LandingPage />}
                    />
                    <Route
                      path="/nearby"
                      element={
                        <div>
                          <Header />
                          <RestaurantList />
                        </div>
                      }
                    />
                    <Route path="/demo" element={<DemoPage />} />
                    <Route path="/error" element={<ErrorPage />} />
                    <Route
                      path="/account/*"
                      element={
                        <AccountInfoPage setRefreshMeal={setRefreshMeal} />
                      }
                    />
                    {roles?.includes("ADMIN") && (
                      <Route
                        path="/admin/*"
                        element={
                          <AdminDashboardPage />
                        }
                      />
                    )}
                    <Route path="*" element={<ErrorPage />} />
                    <Route
                      path="/"
                      element={
                        IsOverwolfDisplay ? (
                          <OverwolfRedirection />
                        ) : (
                          <AuthRedirection />
                        )
                      }
                    />
                    <Route path="/QR1" element={<AuthRedirection />} />
                    <Route path="/QR2" element={<AuthRedirection />} />
                    <Route path="/QR3" element={<AuthRedirection />} />
                    <Route path="/QR4" element={<AuthRedirection />} />
                    <Route path="/QR5" element={<AuthRedirection />} />
                    <Route path="/QR6" element={<AuthRedirection />} />
                    <Route path="/QR7" element={<AuthRedirection />} />
                    <Route path="/QR8" element={<AuthRedirection />} />
                    <Route path="/QR9" element={<AuthRedirection />} />
                    <Route path="/QR10" element={<AuthRedirection />} />
                    <Route path="/privacy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<TermsOfService />} />
                    <Route path="/done" element={<PressDonePromptPage />} />
                    <Route
                      path="/confirmation"
                      element={<ConfirmationPage />}
                    />
                    <Route path="/DiscordCallback" />
                    <Route
                      path="/notifications"
                      element={<MobileNotifications />}
                    />
                    {!IsRunningOverwolf() && (
                      <Route path="/streamers" element={<StreamerPage />} />
                    )}
                    {IsOverwolfDisplay && (
                      <Route
                        path="/Files/index.html"
                        element={<Navigate to={"/"} />}
                      />
                    )}
                  </Routes>
                )}

                {!!userInfo && !!userInfo.address && !inFullMaintenanceMode && (
                  <div className="fixed flex md:hidden justify-between text-slate-200 bottom-0 w-full">
                    <BottomNav userMeals={filteredUserMeals} />
                  </div>
                )}
              </div>
              {!IsOverwolfDisplay &&
                application !== "IOS_CLIENT" &&
                application !== "ANDROID_CLIENT" &&
                !inFullMaintenanceMode && (
                  <div className="hidden md:block">
                    <Footer />
                  </div>
                )}
            </Router>
          </HelmetProvider>
        </GlobalStateProvider>
      </ErrorBoundary>
    </Suspense>
  );
};

export default App;
